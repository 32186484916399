<template>
    <svg width="35" height="34" viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_16_135)">
        <path d="M4.9161 12.5838L0.5 17.0011L4.9161 21.4163L9.3322 17.0011L4.9161 12.5838Z" fill="#FFFFFF"/>
        <path d="M30.0839 21.4163L34.5 17.0011L30.0839 12.5838L25.6678 17.0011L30.0839 21.4163Z" fill="#FFFFFF"/>
        <path d="M13.5597 17.0011L17.5 20.9421L21.4403 17.0011L17.5 13.0601L13.5597 17.0011Z" fill="#FFFFFF"/>
        <path d="M17.5 8.83246L23.5541 14.8862L27.9702 10.471L17.5 0L7.02991 10.471L11.446 14.8862L17.5 8.83246Z" fill="#FFFFFF"/>
        <path d="M17.5 25.1697L11.446 19.1138L7.02991 23.529L17.5 34L27.9702 23.529L23.5541 19.1138L17.5 25.1697Z" fill="#FFFFFF"/>
        </g>
        <defs>
        <clipPath id="clip0_16_135">
        <rect width="34" height="34" fill="white" transform="translate(0.5)"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "Binance"
}
</script>