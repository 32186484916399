<template>
  <div class="relative h-full min-w-screen">
    <App-Header />
    <div class="relative pb-10 sm:py-10">
      <main>
        <div class="max-w-3xl mx-auto sm:px-6 lg:px-8 mt-10">
          <div
            v-if="isConnected && !swap && !initLoading"
            class="max-w-full p-3 mx-auto text-center text-white bg-red-600 rounded"
          >
            There is an ongoing issue at the moment, the team is aware and
            will be resolving ASAP. Please try again after 30min.
          </div>
          <div class="flex flex-row items-center align-center justify-between h-auto py-6 sm:px-12 px-8 mx-3 mt-5 rounded-lg sm:mx-auto bg-mcc-lightblack border-black border mcc-shadow">
            <network :network="smartContractType" />
            <div class="flex items-center" v-if="!isConnected">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="7" fill="#EA3393"/>
              </svg>
              <p class="ml-1 text-mcc-pink">Not Connected</p>
            </div>
            <div class="flex items-center" v-else>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="14" height="14" rx="7" fill="#33EA9D"/>
              </svg>
              <p class="ml-3 text-mcc-green">Connected to <span v-if="smartContractType == 'ERC20'">ETH</span> <span v-else>BSC</span></p>
            </div>
          </div>
          <div
            class="flex flex-col h-auto pb-10 mx-3 mt-5 rounded-lg sm:mx-auto bg-mcc-lightblack border-black border mcc-shadow"
          >
            <div class="flex flex-col sm:px-12 px-8 mt-6">
              <div class="flex items-center md:justify-center">
                <p class="font-large sm:text-2xl text-xl text-white break-all">Enter the amount you want to swap</p>
              </div>
              <div class="relative mt-5">
                <input v-model="swapAmount" :disabled="!swap" type="text" name="price" id="price" class="block w-full pl-6 pr-16 pt-5 pb-5 text-sm text-left text-white placeholder-white bg-mcc-black rounded-lg outline-none sm:text-xl placeholder-opacity-40" placeholder="Enter the amount you want to swap $MCC">
                <button
                  v-if="isConnected && swap"
                  :disabled="!isConnected && initLoading"
                  @click="swapAmount = userBalanceFormatted(swap.token).replace(/,/g,'')"
                  class="absolute inset-y-0 right-0 flex items-center pr-6 text-white"
                >
                  <span class="ml-5">Max</span>
                </button>
              </div>
              <div class="flex flex-col sm:items-center sm:justify-between sm:flex-row sm:my-8 my-6">
                <p class="font-medium text-white break-all">You are about to send: {{ swapAmountFormatted }} $MCC</p>
                <p class="mb-5 font-medium text-white break-all sm:mb-0">
                  $MCC Balance:
                  <span v-if="swap">{{ userBalanceFormatted(swap.token) }}</span
                  ><span v-else>0.00</span>
                </p>
              </div>
              <div class="flex items-center justify-center">
                <button
                  v-if="!isConnected"
                  type="button"
                  @click="init"
                  class="bg-linear-blue inline-flex items-center justify-center w-full p-3 text-base font-extrabold text-white uppercase border-0 border-transparent rounded-lg bg-mcc-black"
                >
                  Connect Wallet
                </button>
                <button
                  v-if="isConnected && initLoading"
                  type="button"
                  class="bg-linear-blue inline-flex items-center justify-center w-full p-3 text-base font-extrabold text-white uppercase border-0 border-transparent rounded-lg bg-mcc-black"
                >
                  Connecting Wallet
                  <Spinner />
                </button>
                <button
                  v-else-if="isConnected"
                  type="button"
                  @click="sendSwap"
                  :disabled="isLoading"
                  class="bg-linear-blue inline-flex items-center justify-center w-full p-3 text-base font-extrabold text-white uppercase border-0 border-transparent rounded-lg bg-mcc-black hover:bg-opacity-40"
                >
                  Initiate Swap
                  <Spinner v-if="isLoading" />
                </button>
              </div>
            </div>
          </div>
          <footer class="mt-12 text-base text-center text-white">
            <a href="https://oklg.io/" target="_blank" class="flex items-center justify-center">
              Powered by 
              OKLG
            </a>
          </footer>
          <div class="flex relative flex-wrap sm:flex-row flex-col items-center h-auto px-8 mx-3 my-20 rounded-lg sm:mx-0 sm:py-0 py-5 sm:h-20 bg-mcc-lightblack border-black border mcc-shadow">
            <div class="flex flex-col mr-auto text-white sm:w-auto w-full">
              <p class="text-lg font-bold" v-if="swap && hasUnclaimedSentFromTarget">
                {{ claimAmount }} <span class="text-sm font-normal">MCC</span>
              </p>
              <p class="text-lg font-bold" v-else>
                0.00 <span class="text-sm font-normal">MCC</span>
              </p>
              <p class="text-sm">Available Tokens</p>
            </div>
            <div class="flex items-center sm:w-2/3 w-full mt-3 mb-3" v-if="swap">
              <button @click="manualClaimModal = true" :disabled="isLoading" class="inline-flex items-center justify-center sm:w-1/2 w-full ml-auto py-1.5 px-4 text-base font-semibold text-white uppercase bg-mcc-pink border-0 border-transparent rounded-lg hover:bg-opacity-40">
                Claim Tokens
              </button>
              <button 
                v-if="!hasUnclaimedInSourceAndNotInitiatedClaiming"
                @click="onRefundModal = true"
                :disabled="isLoading"
                class="inline-flex items-center justify-center w-auto py-1.5 px-4 text-base font-semibold text-white uppercase bg-mcc-black ml-4 bg-opacity-40 border-0 border-transparent rounded-lg hover:bg-opacity-40"
              >
                Refund
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <claim-token-modal
      v-if="manualClaimModal"
      @close-modal="manualClaimModal = false"
    />
    <sent-token-modal
      v-if="successfullySentModal"
      @close-modal="successfullySentModal = false"
    />
    <refund-modal
      v-if="onRefundModal"
      @close-modal="onRefundModal = false"
      @refund="onRefundTokens"
    />
    <notifications position="top right" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import BigNumber from "bignumber.js";
import AppHeader from "./components/AppHeader.vue";
import ClaimTokenModal from "./components/ClaimTokenModal.vue";
import SentTokenModal from "./components/SentTokenModal.vue";
import RefundModal from "./components/RefundModal.vue";
import Spinner from './components/Spinner.vue'
import Network from "./components/Network.vue"

export default {
  components: {
    AppHeader,
    ClaimTokenModal,
    SentTokenModal,
    RefundModal,
    Spinner,
    Network
  },
  data() {
    return {
      isLoading: false,
      swapAmount: null,
      manualClaimModal: false,
      successfullySentModal: false,
      onRefundModal: false,
      swap_id: '',
      unique_identifier: '',
      swap_amount: 0
    }
  },
  computed: {
    ...mapGetters([
      'isConnected',
      'initLoading',
      'activeNetwork',
      'web3',
      'swap'
    ]),
    smartContractType() {
      if (this.isConnected && !this.initLoading) {
        if (this.activeNetwork.network_id == 1) {
          return 'ERC20'
        } else if (this.activeNetwork.network_id == 56) {
          return 'BEP20'
        }
      } else {
        return 'ERC20'
      }
    },
    swapAmountFormatted() {
      if (!this.swapAmount) {
        return 0
      } else {
        return this.swapAmount;
      }
    },
    claimAmount() {
      if (this.swap) {
        const claimAmount = this.swap.unclaimedSentFromTarget;
        return (
          new BigNumber(claimAmount.amount)
            .div(new BigNumber(10).pow(this.swap.targetToken.targetTokenDecimals))
            .toFormat()
        );
      } else {
        return 0;
      }
    },
    hasUnclaimedSentFromSource() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromSource;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false
      }
    },
    hasUnclaimedSentFromTarget() {
      if (this.swap) {
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          targetSwap &&
          new BigNumber(targetSwap.amount).gt(0) &&
          !targetSwap.isComplete &&
          !targetSwap.isRefunded
        );
      } else {
        return false
      }
    },
    hasUnclaimedInSourceAndNotInitiatedClaiming() {
      if (this.swap) {
        const sourceSwap = this.swap.unclaimedSentFromSource;
        const targetSwap = this.swap.unclaimedSentFromTarget;
        return (
          sourceSwap &&
          new BigNumber(sourceSwap.amount).gt(0) &&
          !sourceSwap.isComplete &&
          !sourceSwap.isRefunded &&
          (!targetSwap || sourceSwap.id !== (targetSwap || {}).id)
        );
      } else {
        return false
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof localStorage.getItem("mccLoggedIn") !== 'object' && localStorage.getItem("mccLoggedIn") == 'true') {
        this.init(true)
      }
    })
  },
  methods: {
    ...mapActions([
      "init",
      "sendTokensToSwap",
      "asaasInstanceGasCost",
      "asaasGetLatestUserSwap",
      "refundTokens", 
      "getAllSwapContracts"
    ]),
    userBalanceFormatted(tokenInfo) {
      return new BigNumber(tokenInfo.userBalance)
        .div(new BigNumber(10).pow(tokenInfo.decimals))
        .toFormat(2);
    },
    async sendSwap() {
      try {
        this.isLoading = true;
        if (!this.swapAmount || this.swapAmount == "0.00") return;
        const amountToFormat = new BigNumber(this.swapAmount.replace(/,/g,''))
        const correctSendTokenAmount = new BigNumber(amountToFormat)
          .times(new BigNumber(10).pow(this.swap.token.decimals))
          .toFixed();
        await this.asaasInstanceGasCost(this.swap.sourceContract)
        await this.sendTokensToSwap({
          amount: correctSendTokenAmount,
          sourceContract: this.swap.sourceContract,
          tokenContract: this.swap.token.address,
        })
        this.latestSwap = await this.asaasGetLatestUserSwap(this.swap.sourceContract)
        localStorage.mccLatestSwapId = this.latestSwap.id;
        localStorage.mccLatestSwapTimestamp = this.latestSwap.origTimestamp;
        localStorage.mccLatestSwapNumTokens = new BigNumber(
          correctSendTokenAmount
        ).div(new BigNumber(10).pow(this.swap.token.decimals)).toFormat();
        this.successfullySentModal = true
        this.$notify({ type: "error", text: "Successfully initiated your swap!" });
      } catch(err) {
        this.$notify({ type: "error", text: err.message });
      } finally {
        this.isLoading = false
      }
    },
    async onRefundTokens(refund) {
       try {
            if (refund) {
              this.isLoading = true;
              await this.refundTokens({
                instContract: this.swap.sourceContract,
                id: this.swap.unclaimedSentFromSource.id,
                timestamp: this.swap.unclaimedSentFromSource.origTimestamp,
                amount: this.swap.unclaimedSentFromSource.amount,
              })
              this.$notify({ type: "error", text: "Your tokens were successfully refunded!" });
              await this.getAllSwapContracts();
            } else {
              this.onRefundModal = false
            }
          } catch(err) {
            this.$notify({ type: "error", text: err.message });
          } finally {
            this.isLoading = false;
          }
    }
  }
};
</script>

<style>
.bg-linear-pink {
  background: linear-gradient(90deg, #FB14F6 0%, #FF00FF 100%);
}
.bg-linear-blue {
  background: linear-gradient(232deg,rgba(40,175,239,1) 0%,rgba(165,99,209,1) 100%);
}
.mcc-shadow {
  box-shadow: #0000000d 0 10px 30px;
}
body {
  background: #17181a
}
</style>
